import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventPayload,
  EventType,
} from '@azure/msal-browser';
import { filter } from 'rxjs';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { AuthService } from './core/auth';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'barcode-validator';

  constructor(private _authService: AuthService, private _router: Router) {}

  ngOnInit() {
    this._configureLogging();
    this._authService.handleUserLogIn();
  }

  private _configureLogging(): void {
    if (!environment.production) {
      return;
    }

    var angularPlugin = new AngularPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.azure.logInstrumentationKey,
        extensions: [angularPlugin as any],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this._router },
        },
      },
    });
    appInsights.loadAppInsights();
  }
}
