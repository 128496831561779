import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { catchError, map, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthService } from '../services';
import { environment } from 'src/environments/environment';

const MAX_RETRY_COUNT = 3;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private _accessToken: string = null!;

  constructor(private _authService: AuthService) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (!req.url.startsWith(environment.api.baseUri)) {
      console.log('Startet nicht mit: ', environment.api.baseUri);
      
      return next.handle(req);
    }

    const authenticatedRequest = this._makeAuthenticatedRequest(req);

    let retryCounter = 0;

    return authenticatedRequest.pipe(
      switchMap((request) => {
        return next.handle(request);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && retryCounter < MAX_RETRY_COUNT) {
          retryCounter++;
          return this._makeRequestWithNewAccessToken(req).pipe(
            switchMap((request) => next.handle(request))
          );
        }

        if (retryCounter > MAX_RETRY_COUNT) {
          console.log(
            'Maximale Limit von neuen Anmelde versuchen ausgeschöpft'
          );
        }

        return throwError(() => error);
      })
    );
  }

  private _makeAuthenticatedRequest(
    req: HttpRequest<any>
  ): Observable<HttpRequest<any>> {
    if (this._accessToken == null) {
      return this._makeRequestWithNewAccessToken(req);
    }

    const requestWithAccessToken = this._makeRequestWithAuthenticationHeader(
      req,
      this._accessToken
    );

    return of(requestWithAccessToken);
  }

  private _makeRequestWithNewAccessToken(
    req: HttpRequest<any>
  ): Observable<HttpRequest<any>> {
    return this._authService.getAccessToken().pipe(
      map(({ accessToken }) => {
        this._accessToken = accessToken;
        const requestWithNewAccessToken =
          this._makeRequestWithAuthenticationHeader(req, accessToken);

        return requestWithNewAccessToken;
      })
    );
  }

  private _makeRequestWithAuthenticationHeader(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    const headersWithAuthToken = request.headers.set(
      'Authorization',
      `Bearer ${token}`
    );

    return request.clone({
      headers: headersWithAuthToken,
    });
  }
}

