import { Injectable, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private mslAuthService: MsalService) {}

  /**
   * Returns the access token to be used
   * with non-graph APIs
   */
  public getAccessToken(): Observable<AuthenticationResult> {
    if (this.mslAuthService.instance.getActiveAccount() == null || undefined) {
      this._setActiveAccountIfNotSet();
    }

    return this.mslAuthService.acquireTokenSilent({
      // The scope in MSAL determines the target audience of the token.
      // By default MSAL assumes this to be MS Graph which issues tokens that
      // cannot by read by other resources. To get a regular access token to
      // call your own API, we need to specify a scope that belongs to the Azure Application.
      // In this case, we don't have any custom scopes defined, which is why we use the default scope.
      // https://learn.microsoft.com/en-us/azure/active-directory/develop/scopes-oidc
      scopes: [`${environment.azure.clientId}/.default`],
    });
  }

  public getGraphAccessToken(
    scopes: string[]
  ): Observable<AuthenticationResult> {
    this._setActiveAccountIfNotSet();
    return this.mslAuthService.acquireTokenSilent({ scopes });
  }

  private _setActiveAccountIfNotSet() {
    const activeAccount = this.mslAuthService.instance.getActiveAccount();

    if (activeAccount != null) {
      return;
    }

    const [account] = this.mslAuthService.instance.getAllAccounts();
    this.mslAuthService.instance.setActiveAccount(account);
  }

  public logout() {
    this.mslAuthService.logoutRedirect({
      postLogoutRedirectUri: environment.azure.redirectUri,
    });
  }

  public getActiveUser() {
    const accountInfo = this.mslAuthService.instance.getActiveAccount();
    const activeUsername = accountInfo?.username;

    return activeUsername;
  }

  /**
   * Automatically sets the active user account after the user has logged in.
   */
  public handleUserLogIn() {
    this.mslAuthService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (result === null) {
          // User is already signed in
          return;
        }

        const userHomeID = result.account!.homeAccountId;
        let newActiveAccount =
          this.mslAuthService.instance.getAccountByHomeId(userHomeID);
        this.mslAuthService.instance.setActiveAccount(newActiveAccount);
      },
      error: (error) => console.log(error),
    });
  }
}
