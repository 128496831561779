const API_BASE_URI = 'https://den-integration-dev-weeu-func-bv01.azurewebsites.net/api';

export const environment = {
  production: false,
  azure: {
    redirectUri: 'https://barcode-validator-dev.denner.ch',
    tenantId: '35aa8c5b-ac0a-4b15-9788-ff6dfa22901f',
    clientId: 'c6ee3fdc-b1fc-49b4-bdaa-944c2ab892b4',
    logInstrumentationKey:
      'InstrumentationKey=845277f2-2944-4a80-b21f-a3b75561c0c7;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
  },
  api: {
    baseUri: API_BASE_URI,
    eanUri: `${API_BASE_URI}/ean`,
  },
};
