import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  {
    path: '',
    canActivate: [MsalGuard],
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('./features/home/home.module').then((x) => x.HomeModule),
      },
      {
        path: 'ean',
        loadChildren: () =>
          import('./features/ean-suche/ean-suche.module').then(
            (x) => x.EanSucheModule
          ),
      },
      {
        path: 'info',
        loadChildren: () =>
          import('./features/info/info.module').then((x) => x.InfoModule),
      },
      {
        path: '.well-known/security.txt',
        loadChildren: () =>
          import('./features/security/security.module').then((x) => x.SecurityModule),
      },
      {
        path: 'incident-formular',
        loadChildren: () =>
          import('./features/incident/inc-formular.module').then(
            (x) => x.IncformularModule
          ),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
